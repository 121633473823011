// React imports
import React, { useState } from 'react'

// Remix imports

// Third party imports
import ImageKit from 'imagekit-javascript'
import { IKImage } from 'imagekitio-react'
import ReactPlayer from 'react-player'
import useGlobal from '~/hooks/useGlobal'
import getFileType from '~/utils/getFileType'

// Generated imports

// App imports

type lqip = {
  active: boolean
  quality?: number
  blur?: number
}

export type transformation = {
  width?: string
  height?: string
  crop?: 'at_max' | 'at_least'
  cropMode?: 'pad_resize' | 'maintain_ratio' | 'extract' | 'pad_extract'
  bg?: string
  f?: string
  ar?: string
  r?: string
  lo?: string
}

interface Props {
  canZoom?: boolean
  className?: string
  justVideoThumbnail?: boolean
  loading?: 'lazy'
  lqip?: lqip
  maxHeight?: number
  maxWidth?: number
  path: string
  transformation: transformation
  alt: string
}

const ImageOrVideo: React.FC<Props> = ({
  className = '',
  canZoom,
  justVideoThumbnail,
  loading,
  lqip,
  maxHeight,
  maxWidth,
  path,
  transformation,
  alt,
}) => {
  const global = useGlobal()
  const [isZoomed, setIsZoomed] = useState<any>(null)

  if (!path || !global) {
    return null
  }

  let pathComp = path
  let { isVideo, isGif } = getFileType(path)

  if (!isGif && justVideoThumbnail) {
    if (isVideo) {
      pathComp += '/ik-thumbnail.jpg'
    }
    isVideo = false
  }

  if (isVideo) {
    if (isGif) {
      pathComp += '/ik-gif-video.mp4'
    }
    const ikClient = new ImageKit({
      urlEndpoint: global.ENV.IMAGEKIT_PATH || '',
    })

    pathComp = ikClient.url({
      urlEndpoint: global?.ENV.IMAGEKIT_PATH,
      path: pathComp,
      transformation: [transformation],
    })
  }

  if (isVideo) {
    return (
      <ReactPlayer
        url={pathComp}
        playing
        loop
        volume={0}
        muted
        playsinline
        width={'100%'}
        height={'100%'}
      />
    )
  }

  return (
    <IKImage
      alt={alt}
      onClick={(e: any) => {
        if (canZoom) {
          if (!isZoomed) {
            const imageRect = e.target.getBoundingClientRect()
            const xCenter =
              imageRect.left + (imageRect.right - imageRect.left) / 2
            const yCenter =
              imageRect.top + (imageRect.bottom - imageRect.top) / 2
            const mouseX = e.clientX
            const mouseY = e.clientY
            setIsZoomed([(xCenter - mouseX) / 1.2, (yCenter - mouseY) / 1.2])
          } else {
            setIsZoomed(null)
          }
        }
      }}
      className={className + ' transition-transform ease-in-out'}
      style={{
        transform: isZoomed
          ? `scale(3) translate(${isZoomed[0]}px, ${isZoomed[1]}px)`
          : undefined,
      }}
      path={pathComp}
      // if it is a video and we do ik-gif-video but we also set
      // justVideoThumbnail, then IK crashes, trying to convert the video
      // to jpeg and then trying to turn the jpeg into a thumbnail.
      // So we shall never attempt lqip if the original image was a video or
      // gif.
      lqip={!isVideo ? lqip : undefined}
      transformation={[transformation]}
      width={maxWidth}
      height={maxHeight}
      loading={loading}
    />
  )
}

export default ImageOrVideo
