const getFileType = (path: string) => {
  const pathLower = path.toLowerCase()
  let isVideo = false
  let isGif = false
  const videoFormats = ['.gif', '.mp4', '.mov']
  videoFormats.forEach((format) => {
    if (pathLower.endsWith(format)) {
      isVideo = true
      if (format === '.gif') {
        isGif = true
      }
    }
  })
  return { isVideo, isGif }
}

export default getFileType
